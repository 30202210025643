import { Carousel } from "antd";
import { CarouselRef } from "antd/lib/carousel";
import classNames from "classnames";
import * as React from "react";
import { memo, ReactNode, useRef, useState } from "react";

type TabType = "gradient" | "gray" | "light" | "text";

type TabThemeClass = {
  [key in TabType]: TabClass;
};

type TabClass = {
  tabControl: string;
  tabActive: string;
  tabItem: string;
};

const themes: TabThemeClass = {
  gradient: {
    tabControl: "gradient-tab-control",
    tabActive: "gradient-tab-active",
    tabItem: "gradient-tab-item",
  },
  gray: {
    tabControl: "grey-tab-control",
    tabActive: "grey-tab-active",
    tabItem: "grey-tab-item",
  },
  light: {
    tabControl: "light-tab-control",
    tabActive: "light-tab-active",
    tabItem: "light-tab-item",
  },
  text: {
    tabControl: "text-tab-control",
    tabActive: "text-tab-active",
    tabItem: "text-tab-item",
  },
};

export const TabBar = memo(
  (props: {
    tabs: string[];
    activeIndex: number;
    onSelectIndex: (index: number) => void;
    className?: string;
    type?: TabType;
  }) => {
    const theme = themes[props.type ?? "gradient"];
    return (
      <div
        className={classNames(
          "custom-tabs flex flex-col items-center",
          props.className
        )}
      >
        <div
          className={classNames(
            "h-12 tab-control-wrap flex items-center p-2 overflow-x-scroll w-full sm:w-auto",
            theme.tabControl
          )}
        >
          {props.tabs.map((title, index) => (
            <div
              key={index}
              className={classNames(
                "px-6 sm:px-8  rounded-full h-full text-center flex items-center justify-center cursor-pointer",
                index === props.activeIndex ? theme.tabActive : theme.tabItem
              )}
              onClick={() => {
                props.onSelectIndex(index);
              }}
            >
              <p className="whitespace-nowrap">{title}</p>
            </div>
          ))}
        </div>
      </div>
    );
  }
);

const Tabs = ({
  className,
  children,
  tabs,
  contentWrapClassName,
  type,
  touchMove = true,
}: {
  tabs: string[];
  className?: string;
  children?: ReactNode;
  contentWrapClassName?: string;
  type?: TabType;
  touchMove?: boolean;
}) => {
  const carousel = useRef<CarouselRef>(null);
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <>
      <TabBar
        tabs={tabs}
        className={className}
        type={type}
        activeIndex={activeIndex}
        onSelectIndex={(index) => {
          carousel.current?.goTo(index);
          setActiveIndex(index);
        }}
      />
      <Carousel
        ref={carousel}
        className={classNames(
          "w-full mt-4 rounded-lg overflow-hidden",
          contentWrapClassName
        )}
        dots={false}
        adaptiveHeight
        touchMove={touchMove}
      >
        {children}
      </Carousel>
    </>
  );
};

export default Tabs;
